import React from 'react'
import useDataLayer from '../../hooks/useDataLayer'
import "./ContactForm.scss"
import { Checkbox } from 'semantic-ui-react'
import { Link } from "gatsby"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios'

import { captureEvent } from 'event-service/modules/capture'
import { getSessionId } from 'event-service/modules/utility'

class ContactForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            success: false,
            call: false,
            text: false,
            checkEmail: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comments: '',
            id: this.props.id,
            pageSource: this.props.pageSource,
            pageURL: this.props.pageURL,
            engagement: false
        }

        this.handleCheckedInput = this.handleCheckedInput.bind(this)
        this.handleTextInput = this.handleTextInput.bind(this)

        this.response = React.createRef()

        this.contactSettings = props.data;
        this.dealer = props.dealer;
        this.lead_url = props.lead_url;
    }

    handleCheckedInput(e, data) {
        this.setState({
            [data.name]: data.checked
        })
    }

    handleTextInput(name, value) {
        if(document.getElementById(name).classList.contains('contact-required'))
            document.getElementById(name).classList.remove('contact-required')
        this.setState({
            [name]: value
        })
    }

    phoneMask(e) {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    }

    checkValid(data) {

        return new Promise((resolve, reject) => {
            try {
                let required = ['firstName', 'lastName', 'email', 'phoneNumber']
                let found = []

                required.forEach(req => {
                    if (data[req] === '') {
                        this.response.current.innerText = 'Please fill out all required form inputs.'
                        this.response.current.classList = 'contact-failure'

                        document.getElementById(req).classList.add('contact-required')

                        found.push(req)
                    }
                })

                resolve(found)
            }

            catch (err) {
                reject(err)
            }
        })
    }

    async submitForm(e) {
        this.setState({
            loading: true
        })

        e.preventDefault()

        let data = {}
        data.call = this.state.call
        data.text = this.state.text
        data.checkEmail = this.state.checkEmail
        data.firstName = this.state.firstName
        data.lastName = this.state.lastName
        data.email = this.state.email
        data.phoneNumber = this.state.phoneNumber
        data.comments = this.state.comments
        data.postalcode = this.state.postalcode
        data.siteId = this.props.site_id
        data.pageSource = this.state.pageSource
        data.pageURL = this.state.pageURL
        data.dealerID = ""
        data.sessionID = getSessionId()
        
        //Check if session storage has UTM parameters
        if (sessionStorage.getItem('utmParams')) {
            data.utmParams = JSON.parse(sessionStorage.getItem('utmParams'));
        }

        if (this.props.car) {
            data.vehicle = this.props.car
            data.vehicle.IsNew = true
        }

        let missing = await this.checkValid(data)

        if (missing.length > 0) {
            this.setState({
                loading: false
            })

            return
        }

        axios.post(this.props.lead_url, JSON.stringify(data)) // this needs an actual backend link
            .then(res => {
                this.props.pushToDataLayer("form-submission", res)
                this.props.pushToDataLayer("contact", res)

                this.response.current.innerText = 'Success! The message has been sent!'
                this.response.current.classList = 'contact-success'

                this.setState({
                    loading: false
                })

                this.setState({
                    success: true
                })

                captureEvent({
                events: 'formSubmission',
                leadId: res.data.LeadID,
                formType: 'Contact Form',
                leadType: 'lead',
                displayType: 'in-page',
                prefContact: "N/A",
                formVehicle: {
                    status: "N/A",
                    year: "N/A",
                    make: "N/A",
                    model: "N/A",
                    trim: "N/A",
                    engine: "N/A",
                    transmission: "N/A",
                    interiorColor: "N/A",
                    exteriorColor: "N/A",
                    vin: "N/A",
                    msrp: "N/A",
                    displayedPrice: "N/A",
                    fuelType: "N/A"
                }
                }, {
                handlers: ['Maserati']
                }).then(res => console.log(res)).catch(err => console.log(err))
            })
            .catch(err => {
                console.log(err)

                this.setState({
                    loading: false
                })

                this.response.current.innerText = 'Uh oh! Something went wrong, please try again!'
                this.response.current.classList = 'contact-failure'
            })
    }

    async componentDidMount() {
        async function formShown() {
        captureEvent({
            formType: 'Contact Form',
            displayType: 'in-page',
            events: "formShown",
            formVehicle: {
                status: "N/A",
                year: "N/A",
                make: "N/A",
                model: "N/A",
                trim: "N/A",
                engine: "N/A",
                transmission: "N/A",
                interiorColor: "N/A",
                exteriorColor: "N/A",
                vin: "N/A",
                msrp: "N/A",
                displayedPrice: "N/A",
                fuelType: "N/A"
            }
        }, {
            handlers: ['Maserati']
        }).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
        }
        formShown()
    }

    async formEngagement() {
        //set engagement to true
        if (!this.state.engagement) {
            this.setState({
                engagement: true
            })

            captureEvent({
                formType: 'Contact Form',
                displayType: 'in-page',
                events: "formInitiation",
                formVehicle: {
                    status: "N/A",
                    year: "N/A",
                    make: "N/A",
                    model: "N/A",
                    trim: "N/A",
                    engine: "N/A",
                    transmission: "N/A",
                    interiorColor: "N/A",
                    exteriorColor: "N/A",
                    vin: "N/A",
                    msrp: "N/A",
                    displayedPrice: "N/A",
                    fuelType: "N/A"
                }
            }, {
                handlers: ['Maserati']
            }).then(response => {
                console.log(response)
            }).catch(error => {
                console.log(error)
            })
        }
    }

    render() {
        return (
		<div className="contact-root">
                <div className="contact-header">
                    <h1 className="contact-title">{this.contactSettings.title}</h1>
                    <p className="contact-instructions">{this.contactSettings.text}</p>
                    <Link className="contact-link" to={this.contactSettings.link}>{this.contactSettings.linkText}</Link>
                </div>

                <div className="contact-formCont">
                <div className="contact-inputCont">
                    <label for="firstName">First Name *</label>
                    <input className="contact-input" id="firstName" name="firstName" placeholder="" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont">
                    <label for="lastName">Last Name *</label>
                    <input className="contact-input" id="lastName" name="lastName" placeholder="" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont contact-phone">
                    <label for="phoneNumber">Phone *</label>
                    <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" id="phoneNumber" name="phoneNumber" placeholder="(555) 555-5555"
                        onChange={(e) => this.handleTextInput(e.target.name, e.target.value)}
                        onInput={(e) => this.phoneMask(e)}/>
                </div>
                <div className="contact-inputCont">
                    <label for="email">Email *</label>
                    <input className="contact-input" type="email" id="email" name="email" placeholder="" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont">
                    <label for="postalcode">Zipcode</label>
                    <input className="contact-input" id="postalcode" name="postalcode" placeholder="" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont contact-comment">
                    <label for="comments">Comment</label>
                    <textarea className="contact-input" id="comments" name="comments" placeholder="" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont contact-border">
                    <p className="contact-disclaimer">{this.contactSettings.disclaimer}</p>
                    {
                        !this.state.success && !this.state.loading ?
                            <button className="contact-button" onClick={(e) => this.submitForm(e)}>
                                SUBMIT
                            </button>  :
                            <div className={this.state.success ? "circle-loader-contact load-complete-contact" : "circle-loader-contact"}>
                                <div className={this.state.success ? "checkmark-contact draw" : ""}></div>
                            </div>
                    }
                </div>
                <div className="contact-response" ref={this.response}></div>
                <div className="contact-disclaimer" dangerouslySetInnerHTML={{ __html: this.props.disclaimer}}></div>
            </div>
        </div>
        )
    }
}

function ContactFormWrapped (props) {
    const pushToDataLayer = useDataLayer()
    return (
        <ContactForm pushToDataLayer={pushToDataLayer} {...props}/>
    )
}

export default ContactFormWrapped
